<template>
  <div class="q-ma-md q-gutter-sm">
    <q-card>
      <q-card-section>
        <h4>{{ getRes("Form.Section.ResubmitPricingDocumentsOffer") }}</h4>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="row no-wrap" v-for="(row, index) in fields" :key="index">
          <span class="col-auto text-bold q-mr-md" style="font-size: 17px">
            {{ `${row.key} :` }}
          </span>
          <span class="col-auto" style="font-size: 17px">
            {{ row.value }}
          </span>
        </div>
        <pro-folders class="q-ma-md" :folders="folders1" show-download-button />
      </q-card-section>
    </q-card>
    <q-card>
      <q-card-section>
        <h4>{{ getRes("Form.Field.SubmitOfferResponse") }}</h4>
        <h6 v-if="ptnStatus !== 'Open'" style="color: red">
          {{ getRes("Form.Section.ResubmitPricingDocumentsOfferNotOpened") }}
        </h6>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div
          class="row no-wrap"
          v-for="(row, index) in fields2"
          :key="index"
          v-show="row.value !== 'Invalid date'"
        >
          <span class="col-auto text-bold q-mr-md" style="font-size: 17px">
            {{ `${row.key} :` }}
          </span>
          <span class="col-auto" style="font-size: 17px">
            {{ row.value }}
          </span>
        </div>
        <pro-folders class="q-ma-md" :folders="folders2" show-download-button />
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
/*eslint-disable*/
//import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProFolders from "../../../../components/PROSmart/ProFolders";

export default {
  components: { ProDeckCard, ProDeck, ProFolders },
  data() {
    return {
      fields: [],
      fields2: [],
      folders1: [],
      folders2: [],
      ptnStatus: "",
    };
  },

  methods: {
    formatDate(date) {
      return this.$proSmart.common.getFormattedDate(new Date(date + "0800"));
    },
  },

  async beforeMount() {
    const { ptnId, id: tenderId } = this.$route.params;

    const {
      ptnRequestId,
      ptnStatus,
      closingDate,
      SelectedTenderer,
      BoqTemplate,
    } = await this.$proSmart.formBuilder.getPtnById(this, tenderId, ptnId);

    this.ptnStatus = ptnStatus;

    this.fields.push({
      key: this.getRes("Form.Field.tenderer"),
      value: SelectedTenderer,
    });
    this.fields.push({
      key: this.getRes("Form.Field.RequestId"),
      value: ptnId,
    });
    this.fields.push({
      key: this.getRes("Form.Field.DateTime"),
      value: this.$proSmart.common.getFormattedDate(closingDate),
    });
    this.fields.push({
      key: this.getRes("Form.Field.Status"),
      value: ptnStatus,
    });

    const folders1 = BoqTemplate.fileList.map((object) => ({
      folderDescription: this.getRes("Form.Section.BoqPriceTemplate"),
      ...object,
    }));

    this.$set(this, "folders1", folders1);
    const {
      PtnOfferSubmission,
      submissionDate,
    } = await this.$proSmart.documentUi.getTendererPtnOfferByProcurer(
      this,
      ptnId,
      tenderId
    );

    this.fields2.push({
      key: this.getRes("Form.Field.Submission"),
      value: this.formatDate(submissionDate),
    });

    const folders2 = PtnOfferSubmission.fileList.map((object) => ({
      folderDescription: "",
      ...object,
    }));

    this.$set(this, "folders2", folders2);
  },
};
</script>
